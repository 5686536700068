import styled from 'styled-components'
import { GridStyles } from '../Layout/Grid/Grid'
import { theme } from '../../styles/globalStyles'
import Image from 'next/image'
import Link from 'next/link'

export const GridContainer = styled.div`
	${GridStyles}
`

export const Card = styled.div`
	grid-column: 1/-1;
	display: flex;
	flex-direction: row;
	@media screen and (max-width: ${theme.breakpoints.tabletMax}) {
		flex-direction: column;
		gap: 16px;
	}
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	justify-content: center;
	padding: 56px 80px;
	background-color: ${theme.colors.gold50};
	flex-basis: 0;
	flex-grow: 1;

	@media screen and (max-width: ${theme.breakpoints.tabletMax}) {
		padding: 20px 30px;
		gap: 16px;
	}
`

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const Title = styled.div`
	font-size: 32px;
	line-height: 40px;
	color: var(--grey800);

	@media screen and (max-width: ${theme.breakpoints.tabletMax}) {
		font-size: 25px;
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 40px;
`

export const DocumentLink = styled(Link)`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
`

export const ImageContainer = styled.div`
	position: relative;
	flex-basis: 40%;
`

export const StyledImage = styled(Image)`
	width: 100%;
	height: 100%;
`