import React from 'react'

const KvikaArrow: React.FC = () => {
	return (
		<svg
			width='35'
			height='14'
			viewBox='0 0 35 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M34.1797 7.45312L34.6484 7L34.1797 6.54688L27.8047 0.796875L27.3516 0.367188L26.4922 1.26562L26.9453 1.69531L32.1953 6.375H0.625H0V7.625H0.625H32.1953L26.9453 12.2969L26.4922 12.7266L27.3594 13.6328L27.8125 13.2031L34.1875 7.45312H34.1797Z'
				fill='#E8DFD1'
			/>
		</svg>
	)
}


export default KvikaArrow
