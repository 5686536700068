import React from 'react'
import { IBaseSlice, IPrismicImage } from '../../types/prismic/baseTypes'
import { IPrismicLink } from '../../types/prismic/baseTypes'
import { RichTextField } from '@prismicio/types'
import { PrismicRichText } from '@prismicio/react'
import {
	Container,
	GridContainer,
	ImageContainer,
	StyledImage,
	TextContainer,
	TextContent,
	Title,
	TopText,
} from './style'
import { useRouter } from 'next/router'
import KvikaArrow from '../icons/KvikaArrow'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'

export interface IContentCard extends IBaseSlice {
	primary: {
		card_title: string
		card_description: RichTextField
		cardtoptext?: string
		image: IPrismicImage
		imageonright: boolean
		link: IPrismicLink
	}
	slice_type: 'contentcard'
}

type Props = {
	sliceData: IContentCard
}

const ContentCard: React.FC<Props> = ({ sliceData }) => {
    const router = useRouter();
	const { pages } = usePrismicRoutingContext()

    const handleCardClick = () => {
        const link = resolvePrismicLink(sliceData.primary.link, pages);

        if(link) {
            router.push(link);
        }
    }

	return (
		<GridContainer>
			<Container $imageOnRight={sliceData.primary.imageonright}>
				<TextContainer>
					{sliceData.primary.cardtoptext && (
						<TopText $imageOnRight={sliceData.primary.imageonright}>{sliceData.primary.cardtoptext}</TopText>
					)}
					<TextContent $imageOnRight={sliceData.primary.imageonright} onClick={handleCardClick}>
						<Title>{sliceData.primary.card_title}</Title>
						<PrismicRichText field={sliceData.primary.card_description} />
    					<KvikaArrow />
					</TextContent>
				</TextContainer>
				{sliceData.primary.image.url && (
					<ImageContainer>
						<StyledImage
							src={sliceData.primary.image.url}
							alt='card-alt'
							width={0}
							height={0}
							sizes='100vw'
						/>
					</ImageContainer>
				)}
			</Container>
		</GridContainer>
	)
}

export default ContentCard
