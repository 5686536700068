import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { RichTextBlock } from 'prismic-reactjs'
import { media } from '../../../utils/style-utils'
import { FullBleedGrid } from '../../Layout/Grid/Grid'
import {
	IBaseSlice,
	IPrismicImage,
	IPrismicLink,
} from '../../../types/prismic/baseTypes'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import RichText from '../../RichText/RichText'
import ClippedRectangle from '../../../../public/svg/ClippedRectangle.svg'
import Image from 'next/image'
import { PrismicDocument } from '@prismicio/types'
import {theme} from "../../../styles/globalStyles"

export interface IHalfImageDescriptionSlice extends IBaseSlice {
	primary: {
		background: IPrismicImage
		heading?: string
		heading_color?: 'default' | 'gold'
		description?: RichTextBlock[] | string
		imageleft?: boolean
		image_style?: 'darkGold' | 'lightGold'
		image_first_on_mobile?: boolean
		video?: PrismicDocument
	}
	items: {
		link_button: IPrismicLink
		button_text: string
	}[]
	slice_label?: string
	slice_type: 'halfimagedescription'
}

const HalfImageDescription: React.FC<IHalfImageDescriptionSlice> = ({
	primary,
	items,
}) => {
	const { pages } = usePrismicRoutingContext()
	const reverse = primary?.imageleft
	const isImageFirst = primary?.image_first_on_mobile
	const isDescriptionRichText = typeof primary?.description === 'object'

	return (
		<Grid>
			<TextContainer reverse={reverse} isImageFirst={isImageFirst}>
				<SCCaptionHeading
					headingColor={primary.heading_color}
					className='primary'
				>
					{primary?.heading}
				</SCCaptionHeading>
				{isDescriptionRichText ? (
					<RichTextWrapper>
						<RichText content={primary.description as RichTextBlock[]} />
					</RichTextWrapper>
				) : (
					<SCCaptionDescription className='primary'>
						{primary?.description as string}
					</SCCaptionDescription>
				)}
				{items.length >= 1 && (
					<ButtonsContainer>
						{items?.map((item, index) => (
							<Link
								key={index}
								href={resolvePrismicLink(item.link_button, pages) ?? '#'}
								legacyBehavior
							>
								<SliceButton>{item.button_text}</SliceButton>
							</Link>
						))}
					</ButtonsContainer>
				)}
				<Icon src={ClippedRectangle} alt='clipped rect' width={24} height={4} />
			</TextContainer>
			<MediaContainer reverse={reverse}>
				{primary.video?.url ? (
					<StyledVideo
						autoPlay
						muted
						loop
					>
						<source src={primary.video.url ?? ""} />
					</StyledVideo>
				) : (
					<StyledImage src={primary.background.url} />
				)}
			</MediaContainer>
		</Grid>
	)
}

export default HalfImageDescription

const ButtonsContainer = styled.div`
	justify-content: space-between;
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;

	button {
		cursor: pointer;
	}

	${media.tablet`
		flex-direction: column;
	`}
`

const SliceButton = styled.button`
	flex-grow: 1;
	background-color: transparent;
	border: 2px solid ${({ theme }) => theme.colors.gold550};
	color: ${({ theme }) => theme.colors.gold550};
	border-radius: 2px;
	padding: 16px 32px;

	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1rem;

	&:hover {
		background-color: ${({ theme }) => theme.colors.gold550};
		color: white;
	}
`

const Grid = styled(FullBleedGrid)`
	min-height: 754px;

	${media.phone`
		min-height: 454px;
	`}
`

const TextContainer = styled.div<{ reverse?: boolean; isImageFirst?: boolean}>`
	grid-column: ${({ reverse }) => (reverse ? '9 / span 5' : '2 / span 5')};
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;

	${media.phone`
		grid-column: 3/-3;
		grid-row: ${({ isImageFirst }) => (isImageFirst ? 2 : 1)};
		padding: 20px 0;
		min-height: 280px;
		gap: 0;
	`}
`

const SCCaptionHeading = styled.h1<{ headingColor?: 'default' | 'gold' | 'grey'}>`
	font-weight: 600;
	font-size: 3.5625rem;
	line-height: 4rem;
	margin: 0;
	padding: 0;
	font-family: Adobe Caslon Pro, serif;
	font-weight: 400 !important;

	color: ${({ theme, headingColor }) =>
		headingColor === 'gold' ? theme.colors.gold550 : headingColor === 'grey' ? theme.colors.grey800 : 'inherit'};
`
const SCCaptionDescription = styled.span`
	font-size: 1.375rem;
	line-height: 2rem;
	font-family: AdobeCaslonPro, serif;
	font-weight: 400;

	${media.phone`
		font-size: 1.125rem;
	`}
`

const RichTextWrapper = styled.div`

	* > p {
		font-size: 1.375rem;
		line-height: 2rem;
		font-family: AdobeCaslonPro, serif;
		font-weight: 400;
		color: ${theme.colors.grey800};
	}

	* > ul {
		font-size: 1.25rem;
		line-height: 2.5rem;
		font-family: AdobeCaslonPro, serif;
		line-height: 1.2;
		color: ${theme.colors.grey800};

		${media.phone`
			padding-left: 0;
		`}
	}
`

const MediaContainer = styled.div<{ reverse?: boolean }>`
	grid-column: ${({ reverse }) => (reverse ? '1 / span 7' : '8 / span 7')};
	grid-row: 1;

	${media.phone`
		grid-column: 1/-1;
		grid-row: inherit;
	`}
`

const StyledVideo = styled.video`
	height: 100%;
	width: 100%;
	object-fit: fill;
`

const StyledImage = styled.img`
	height: 100%;
	width: 100%;
`

const Icon = styled(Image)`
	${media.phone`
		display: none;
	`}
`
