import styled from "styled-components";
import { GridStyles } from "../Layout/Grid/Grid";
import { PrismicImage } from '@prismicio/react'
import {theme} from "../../styles/globalStyles"
import { media } from "../../utils/style-utils";

export const GridContainer = styled.div`
    ${GridStyles}
`

export const Container = styled.div`
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    ${media.phone`
        gap: 0;    
    `}
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    * {
        font-family: AdobeCaslonPro, serif;
        color: ${theme.colors.grey800};
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 40px;
        line-height: 60px;
        color: ${theme.colors.goldenGrey600};
    }
`

export const BrandGridContainer = styled.div<{$columns: string}>`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns}, 1fr);
    column-gap: 80px;
    row-gap: 48px;
    padding: 58px 0;

    ${media.phone`
        column-gap: 20px;
        row-gap: 20px;    
    `}
`

export const StyledImage = styled(PrismicImage)`
    width: 100%;
    height: 125px;
    object-fit: contain;
`



