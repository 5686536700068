import React from 'react'
import { IBaseSlice } from '../../types/prismic/baseTypes'
import { PrismicDocument, RichTextField } from '@prismicio/types'
import { IPrismicImage } from '../../types/prismic/baseTypes'
import Image from 'next/image'
import { PrismicRichText } from '@prismicio/react'
import Button, { ButtonBackgroundType } from '@kvika/button'
import PDFIcon from '../../../public/svg/pdf.svg'
import Link from 'next/link'
import { IPrismicLink } from '../../types/prismic/baseTypes'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import {
	ButtonContainer,
	Card,
	Container,
	ContentContainer,
	DocumentLink,
	GridContainer,
	ImageContainer,
	StyledImage,
	Title,
} from './style'

export interface IFeatureContentSlice extends IBaseSlice {
	primary: {
		card_title: string
		card_description: RichTextField
		document: PrismicDocument
		image: IPrismicImage
		page_link: IPrismicLink
		button_label?: string
		document_label?: string
	}
	slice_type: 'featurecontentslice'
}

type Props = {
	sliceData: IFeatureContentSlice
}

const FeatureContentSlice: React.FC<Props> = ({ sliceData }) => {
	const { pages } = usePrismicRoutingContext()

	return (
		<GridContainer>
			<Card>
				<ImageContainer>
					{sliceData.primary.image.url && (
						<StyledImage
							src={sliceData.primary.image.url}
							alt={sliceData.primary.image.alt ?? 'Feature content slice image'}
							width={0}
							height={0}
							sizes='100vw'
						/>
					)}
				</ImageContainer>
				<Container>
					<ContentContainer>
						<Title>{sliceData.primary.card_title}</Title>
						<PrismicRichText field={sliceData.primary.card_description} />
					</ContentContainer>
					<ButtonContainer>
						{sliceData.primary.page_link.id && (
							<Link
								href={
									resolvePrismicLink(sliceData.primary.page_link, pages) ?? '#'
								}
							>
								<Button
									type='button'
									backgroundType={ButtonBackgroundType.Outlined}
								>
									{sliceData.primary.button_label ?? "Nánar um útboð"}
								</Button>
							</Link>
						)}
						{sliceData.primary.document.url && (
							<DocumentLink
								href={sliceData.primary.document.url}
								download={sliceData.primary.document.id}
							>
								<Image src={PDFIcon} width={20} height={20} alt='pdf-icon' />
								{sliceData.primary.document_label ?? "Sækja fjárfestakynningu"}
							</DocumentLink>
						)}
					</ButtonContainer>
				</Container>
			</Card>
		</GridContainer>
	)
}

export default FeatureContentSlice
