import React from 'react'
import { IBaseSlice } from '../../types/prismic/baseTypes'
import { ImageFieldImage, RichTextField } from '@prismicio/types'
import { TitleField } from '@prismicio/client'
import { PrismicRichText } from '@prismicio/react'
import {
	BrandGridContainer,
	Container,
	GridContainer,
	TextContainer,
    StyledImage
} from './style'

export interface IBrand extends IBaseSlice {
	primary: {
		heading: TitleField
		text: RichTextField
		numberofcolumns: '2' | '3' | '4' | undefined
	}
	items: Array<{
		brand: ImageFieldImage
	}>
	slice_type: 'brands'
}

type Props = {
	sliceData: IBrand
}

const Brands: React.FC<Props> = ({ sliceData }) => {
	return (
		<GridContainer>
			<Container>
				<TextContainer>
					<PrismicRichText field={sliceData.primary.heading} />
					<PrismicRichText field={sliceData.primary.text} />
				</TextContainer>
				<BrandGridContainer $columns={sliceData.primary.numberofcolumns ?? '3'}>
					{sliceData.items.map((item) => (
						<StyledImage field={item.brand} key={item.brand.url} />
					))}
				</BrandGridContainer>
			</Container>
		</GridContainer>
	)
}

export default Brands
