import { GridStyles } from '../Layout/Grid/Grid'
import styled, { keyframes } from 'styled-components'
import Image from 'next/image';
import {theme} from "../../styles/globalStyles";
import { media } from '../../utils/style-utils';

export const GridContainer = styled.div`
    ${GridStyles}
`

export const Container =  styled.div<{$imageOnRight: boolean}>`
    grid-column: 1/-1;
    display: flex;
    flex-direction: ${({$imageOnRight}) => $imageOnRight ? "row" : "row-reverse"};    
    
    ${media.phone`
        flex-direction: column;
    `}
`

export const ImageContainer = styled.div`
    flex: 1;
    min-height: 646px;

    ${media.phone`
        min-height: 454px;    
    `}
`   

export const TextContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    ${media.phone`
        padding: 40px 0 20px 0;            
    `}
`

const slideDown = keyframes`
    from {
        padding: 0;
    }

    to {
        padding-top: 50px;
    }
`

export const TextContent = styled.div<{$imageOnRight: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 32px;

    ${({$imageOnRight}) => $imageOnRight ? "margin-right: 15%;" : "margin-left: 15%;"}
    
    > svg {
        margin-top: 8px; 
    }

    &:hover {
        cursor: pointer;
        animation: ${slideDown} 0.2s ease-in;
        animation-fill-mode: forwards;

        > svg > path {
            fill: ${theme.colors.gold300};
        }
            
        > h1 {
            text-decoration: underline ${theme.colors.gold300};
        }
    }

    ${media.phone`
        margin-right: 0; 
        margin-left: 0;   
        gap: 16px;
    `}
`

export const Title = styled.h1`
    font-size: 40px;
    line-height: 31.6px;
    font-family: AdobeCaslonPro, serif;
    margin: 0;
`

export const TopText = styled.div<{$imageOnRight: boolean}>`
    position: absolute;
    top: 0;
    ${({$imageOnRight}) => $imageOnRight ? "left: 0" : "right: 0"};
	font-family: 'AkzidenzGroteskPro', serif;
    color: ${theme.colors.gold400};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
`

export const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`

